@import "./bootstrap/_bootstrap.scss";

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

@import "theming.scss";

html,
body,
#root {
    min-height: 100vh;    
}

body{
    background-color: #ededed;
}
body[data-bs-theme="dark"]{
    background-color: #151515;
}

.form-control:focus {
    box-shadow: 0 0 2px 2px rgb(86 86 86 / 25%);
}

.form-control.bi {
    font-family: var(--bs-body-font-family), 'bootstrap-icons';
}

.key-value {
    user-select: all;
}

*::selection {
    background-color: #424242;
    color: #fff;
}

.h-0 {
    height: 0;
}

* {
    scrollbar-width: thin;
}